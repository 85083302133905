import { atom, useAtom } from "jotai";

/**
 * Global interval that sets a new Date() every TIMEOUT ms.
 */

const nowAtom = atom<Date>(new Date());
let timer: NodeJS.Timeout;

nowAtom.onMount = (setNowAtom) => {
  const updateTimer = () => {
    void setNowAtom(new Date());
    clearTimeout(timer);
    timer = setTimeout(updateTimer, 1000 * 60); // 1 min
  };

  const initTimer = () => {
    // We want the update to trigger 1 second after every new minute. HH:MM:01
    const secondsUntilStart = (61 - new Date().getSeconds()) * 1000;
    setTimeout(() => updateTimer(), secondsUntilStart);
  };

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      initTimer();
    } else {
      clearTimeout(timer);
    }
  });

  initTimer();

  return () => clearTimeout(timer);
};

export const useNow = (): Date => {
  const [now] = useAtom(nowAtom);
  return now;
};
